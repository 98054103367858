export const CHANNEL_OPTIONS = [
  { key: 1, text: 'OpenAI', value: 1, color: 'green' },
  { key: 14, text: 'Anthropic Claude', value: 14, color: 'black' },
  { key: 3, text: 'Azure OpenAI', value: 3, color: 'olive' },
  { key: 11, text: 'Google PaLM2', value: 11, color: 'orange' },
  { key: 15, text: 'Baidu Wenxin Qianfan', value: 15, color: 'blue' },
  { key: 17, text: 'Ali Tongyi Qianwen', value: 17, color: 'orange' },
  { key: 18, text: 'iFlytek Spark Cognition', value: 18, color: 'blue' },
  { key: 16, text: 'Intelligent Mapping ChatGLM', value: 16, color: 'violet' },
  { key: 19, text: '360 Intelligent Brain', value: 19, color: 'blue' },
  { key: 23, text: 'Tencent Hunyuan', value: 23, color: 'teal' },
  { key: 8, text: 'Custom channels', value: 8, color: 'pink' },
  { key: 22, text: 'knowledge base：FastGPT', value: 22, color: 'blue' },
  { key: 21, text: 'knowledge base：AI Proxy', value: 21, color: 'purple' },
  { key: 20, text: 'proxy：OpenRouter', value: 20, color: 'black' },
  { key: 2, text: 'proxy：API2D', value: 2, color: 'blue' },
  { key: 5, text: 'proxy：OpenAI-SB', value: 5, color: 'brown' },
  { key: 7, text: 'proxy：OhMyGPT', value: 7, color: 'purple' },
  { key: 10, text: 'proxy：AI Proxy', value: 10, color: 'purple' },
  { key: 4, text: 'proxy：CloseAI', value: 4, color: 'teal' },
  { key: 6, text: 'proxy：OpenAI Max', value: 6, color: 'violet' },
  { key: 9, text: 'proxy：AI.LS', value: 9, color: 'yellow' },
  { key: 12, text: 'proxy：API2GPT', value: 12, color: 'blue' },
  { key: 13, text: 'proxy：AIGC2D', value: 13, color: 'purple' }
];